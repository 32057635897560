<template>
    <v-container>
        <h4 class="mt-4">{{ this.$t("message.title-sales-orderList") }}</h4>

       <!-- <FilterSalesOrders class="mb-5" />  -->
        <FormFilterSales class="mb-5" :salesType="'orders'"/>

       <!-- DATA TABLE -->
        <v-data-table
            :headers="headers"
            :items="salesOrders"
            class="elevation-0"
            hide-default-header
            items-per-page-all-text
            :hide-default-footer="true"
            disable-pagination
            >
            <template v-slot:header="{ props }" style="min-height: 50px; overflow: auto">
                <th class="custom-th text-center">
                {{ props.headers[0].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 120px">
                {{ props.headers[1].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 120px">
                {{ props.headers[2].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 250px">
                {{ props.headers[3].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[4].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[5].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[6].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[7].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[8].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[9].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 250px">
                {{ props.headers[10].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[11].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[12].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[13].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[14].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[15].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[16].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[17].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[18].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[19].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[20].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 240px">
                {{ props.headers[21].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 200px">
                {{ props.headers[22].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 100px">
                {{ props.headers[23].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[24].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[25].text }}
                </th>
                <th class="custom-th text-center" style="min-width: 150px">
                {{ props.headers[26].text }}
                </th>
            </template>

            
            <!-- ADD CLICKABLE REDIRECT BY ORDER NUMBER -->
            <template v-slot:[`item.order_no`]="{ item }">
                <a href="" @click.prevent="toOrderDetail(item.id)">{{ item.order_no }}</a>
            </template>

            <!-- ADD CLICKABLE REDIRECT BY CUSTOMER NAME -->
            <template v-slot:[`item.customer_name`]="{ item }">
                <a href="" @click.prevent="toCustomerDetail(item.customer_id)">{{ item.customer_name }}</a>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import FilterSalesOrders from '../components/FilterSalesOrders.vue'
import FormFilterSales from '../components/FormFilterSales.vue';
import moneyFormat from '../helpers/moneyFormat'
export default {
    name: "SalesOrderList",
    components: {
        FilterSalesOrders,
        FormFilterSales
    },
    data() {
        return {

        }
    },
    methods: {
        async dispatchSalesOrders() {
            await this.$store.dispatch("sales_module/fetchSalesOrders")
        },
        toOrderDetail(order_id) {
            this.$router.push(`/orderDetail/${order_id}`)
        },
        toCustomerDetail(customer) {
            this.$router.push(`/customer/${customer}`)
        }
    },
    computed: {
        salesOrders() {
            let dataSales = this.$store.state.sales_module.salesOrders;

            if(dataSales) {
                dataSales.map((sales) => {
                    sales.total_amount_w_delivery_fee_per_store_actual = moneyFormat(sales.total_amount_w_delivery_fee_per_store_actual)
                    sales.refundable_amount = moneyFormat(sales.refundable_amount)
                    sales.total_amount_w_delivery_fee_per_store = moneyFormat(sales.total_amount_w_delivery_fee_per_store)
                    sales.logistic_price_actual = moneyFormat(sales.logistic_price_actual)
                    sales.platform_fee = moneyFormat(sales.platform_fee)
                    sales.stripe_fee = moneyFormat(sales.stripe_fee)
                    sales.sales_amount = moneyFormat(sales.sales_amount)
                    sales.amount_of_deposit = moneyFormat(sales.amount_of_deposit)
                    sales.subtotal_exclude_taxable_value_8 = moneyFormat(sales.subtotal_exclude_taxable_value_8)
                    sales.subtotal_consumption_tax_8 = moneyFormat(sales.subtotal_consumption_tax_8)
                    sales.subtotal_exclude_taxable_value_10 = moneyFormat(sales.subtotal_exclude_taxable_value_10)
                    sales.subtotal_consumption_tax_10 = moneyFormat(sales.subtotal_consumption_tax_10)
                    sales.subtotal_include_taxable_value_8 = moneyFormat(sales.subtotal_include_taxable_value_8)
                    sales.subtotal_consumption_taxable_8 = moneyFormat(sales.subtotal_consumption_taxable_8)
                    sales.subtotal_include_taxable_value_10 = moneyFormat(sales.subtotal_include_taxable_value_10)
                    sales.subtotal_consumption_taxable_10 = moneyFormat(sales.subtotal_consumption_taxable_10)
                    sales.subtotal_non_taxable_value = moneyFormat(sales.subtotal_non_taxable_value)
                    sales.subtotal = moneyFormat(sales.subtotal)
                    sales.subtotal_tax_amount = moneyFormat(sales.subtotal_tax_amount)
                })
            }

            return dataSales
        },
        headers() {
            return [
                { text: "ID", align: "center", value: "id" },
                { text: "販売店舗", value: "store_name", align: "center", },
                { text: "購入者", value: "customer_name", align: "center",  },
                { text: "配送先", align: "center", value: "delivery_destination" },
                {
                    text: this.$t("message.label-order-number"),
                    align: "center",
                    value: "order_no"
                },
                {
                text: this.$t("message.label-invoice"),
                value: "invoice_no",
                align:"center", 
                },
                {
                    text: this.$t("message.label-sales-total-amount-actual"),
                    align: "center",
                    value: "total_amount_w_delivery_fee_per_store_actual"
                },
                {
                    text: this.$t("message.label-sales-refund-amount"),
                    align: "center", 
                    value: "refundable_amount"
                },
                {
                    text: this.$t("message.label-sales-total-amount-delivery-fee-store"),
                    align: "center", 
                    value: "total_amount_w_delivery_fee_per_store"
                },
                {
                    text: this.$t("message.label-sales-logistic-actual"),
                    align: "center", 
                    value: "logistic_price_actual",
                    
                },
                {
                    text: this.$t("message.label-sales-platform-fee"),
                    align: "center",
                    value: "platform_fee",
                    
                },
                {
                    text: this.$t("message.label-sales-stripe-fee"),
                    align: "center",
                    value: "stripe_fee",
                    
                },
                {
                    text: this.$t("message.label-sales-amount"),
                    align: "center",
                    value: "sales_amount"
                },
                {
                    text: this.$t("message.label-sales-deposit-amount"),
                    align: "center", 
                    value: "amount_of_deposit"
                },
                {
                    text: this.$t("message.label-sales-subtotal_exclude_taxable_value_8"),
                    align: "center",
                    value: "subtotal_exclude_taxable_value_8"
                },
                {
                    text: this.$t("message.label-sales-subtotal_consumption_tax_8"),
                    align: "center",
                    value: "subtotal_consumption_tax_8"
                },
                {
                    text: this.$t("message.label-sales-subtotal_exclude_taxable_value_10"),
                    align: "center",
                    value: "subtotal_exclude_taxable_value_10"
                },
                {
                    text: this.$t("message.label-sales-subtotal_consumption_tax_10"),
                    align: "center",
                    value: "subtotal_consumption_tax_10"
                },
                {
                    text: this.$t("message.label-sales-subtotal_include_taxable_value_8"),
                    align: "center",
                    value: "subtotal_include_taxable_value_8"
                },
                {
                    text: this.$t("message.label-sales-subtotal_consumption_taxable_8"),
                    align: "center",
                    value: "subtotal_consumption_taxable_8"
                },
                {
                    text: this.$t("message.label-sales-subtotal_include_taxable_value_10"),
                    align: "center",
                    value: "subtotal_include_taxable_value_10"
                },
                {
                    text: this.$t("message.label-sales-subtotal_consumption_taxable_10"),
                    align: "center",
                    value: "subtotal_consumption_taxable_10"
                },
                {
                    text: this.$t("message.label-sales-subtotal_non_taxable_value"),
                    align: "center",
                    value: "subtotal_non_taxable_value"
                },
                {
                    text: this.$t("message.label-sales-subtotal"),
                    align: "center",
                    value: "subtotal"
                },
                {
                    text: this.$t("message.label-sales-subtotal_tax_amount"),
                    align: "center",
                    value: "subtotal_tax_amount"
                },
                {
                text: this.$t("message.order-date"),
                value: "created_at",
                align: "center",
                },
                { text: this.$t("message.delivery-date"), value: "delivery_date", align: "center", },
            ]
        }
    },
    created() {
        this.dispatchSalesOrders()
    }
}
</script>

<style>
.custom-th {
  background: #3949ab !important;
  padding: 12px;
  color: white;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}

thead tr th {
  color: white !important;
  font-size: 15px !important;
}
</style>